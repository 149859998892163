import {
  CustomerInformationClass,
  RegCustomerInformationList,
  ReqCustomerInformationClass,
} from '@/model/customerManagement/listedIndustry'
import { $axios } from '../config/axios'
/**
 * @description:上市行业资讯分页
 * @param {ReqCustomerInformationClass} data
 * @return {*}
 * @see:http://wiki.agile.dgyiheda.com/wiki/kb/agile126?pageCode=6588
 */
export const PageInformationCustomer = (
  data: ReqCustomerInformationClass
): Promise<RegCustomerInformationList> => {
  return $axios.post('api/bcic/customer/information/page', data)
}

/**
 * @description:新增行业资讯信息
 * @param {CustomerInformationClass} data
 * @return {*}
 * @see:http://wiki.agile.dgyiheda.com/wiki/kb/agile126?pageCode=7132
 */
export const AddInformationCustomer = (data: CustomerInformationClass): Promise<unknown> => {
  return $axios.post('api/bcic/customer/information/add', data)
}

/**
 * @description:通过导入Excel批量新增
 * @param {FormData} data
 * @return {*}
 * @see:http://wiki.agile.dgyiheda.com/wiki/kb/agile126?pageCode=7193
 */
export const ImportInformationCustomer = (data: FormData): Promise<unknown> => {
  return $axios.post('api/bcic/customer/information/import', data)
}

/**
 * @description:根据id批量删除资讯信息
 * @param {string[]} data
 * @return {*}
 * @see:http://wiki.agile.dgyiheda.com/wiki/kb/agile126?pageCode=7209
 */
export const DeleteInformationCustomer = (data: string[]): Promise<unknown> => {
  return $axios.delete('api/bcic/customer/information/delete', { data })
}

/**
 * @description:更新资讯信息
 * @param {CustomerInformationClass} data
 * @return {*}
 * @see:http://wiki.agile.dgyiheda.com/wiki/kb/agile126?pageCode=7229
 */
export const UpdateInformationCustomer = (data: CustomerInformationClass): Promise<unknown> => {
  return $axios.put('api/bcic/customer/information/update', data)
}

/**
 * @description:通过id获取客户资讯详情
 * @param {string} data
 * @return {*}
 * @see:http://wiki.agile.dgyiheda.com/wiki/kb/agile126?pageCode=8155
 */
export const InfoInformationCustomer = (data: string): Promise<CustomerInformationClass> => {
  return $axios.get('api/bcic/customer/information/info', { params: { id: data } })
}

/**
 * @description:资讯添加关注信息
 * @param {string} id
 * @return {*}
 * @see:http://wiki.agile.dgyiheda.com/wiki/kb/agile126?pageCode=9905
 */
export const AddFollowInformationCustomer = (id: string): Promise<unknown> => {
  return $axios.post('api/bcic/information/follow/add', {}, { params: { id } })
}

/**
 * @description:上市公司年资讯取消关注信息
 * @param {string} id
 * @return {*}
 * @see:http://wiki.agile.dgyiheda.com/wiki/kb/agile126?pageCode=9912
 */
export const CancelFollowInformationCustomer = (id: string): Promise<unknown> => {
  return $axios.delete('api/bcic/information/follow/cancel', { params: { id } })
}
